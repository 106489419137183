import { request } from './utils';

const serializeInterest = (data) => {
  const serializedData = {
    ...data,
  };
  return serializedData;
};

export const postInterest = async (data, serialize = serializeInterest, config = undefined) => {
  const url = `public/interest/`;
  return await request.post(url, serialize(data), config);
};
