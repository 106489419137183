import axios from 'axios';
import applyConverters from 'axios-case-converter';
// import fetchAdapter from '@vespaiach/axios-fetch-adapter';
import 'regenerator-runtime/runtime.js';
import Cookies from 'universal-cookie';
const cookies = new Cookies();
const token = cookies.get('token');

export const appRequest = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_SITE_URL}/api/`,
    // adapter: fetchAdapter,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const request = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
    // adapter: fetchAdapter,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);

export const authRequest = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
    // adapter: fetchAdapter,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Token ${token}`,
    },
  })
);

export const baseRequest = axios.create({
  // adapter: fetchAdapter,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

export const reqNoIntercept = applyConverters(
  axios.create({
    baseURL: `${process.env.NEXT_PUBLIC_API_URL}/`,
    // adapter: fetchAdapter,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  })
);
